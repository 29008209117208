<template>
	<v-container v-if="loading && show" class="pa-0">
		<v-skeleton-loader :type="type" class="elevation-1 mb-4" />
	</v-container>
</template>

<script>
export default {
	props: {
		loading: {
			type: Boolean,
		},
		type: {
			type: String,
			default: () => '',
		},
	},
	data: () => ({
		show: false,
	}),
	created() {
		setTimeout(() => {
			this.show = true;
		}, 100);
	},
};
</script>

<style></style>
